import React, { useContext, useEffect, useState } from "react";
import { Button, IconButton, MaterialSymbolsIcon, Notification } from "core";
import { classNames } from "core";
import { useAppDispatch } from "source/redux";
import { removeSnackbar } from "source/redux/ui";
import { useActiveMatrixId } from "source/hooks/matrix/useActiveMatrixId";
import { useFeatureFlag } from "source/hooks/common/useFeatureFlag";
import { calculateCellReviewStats } from "source/utils/matrix/cellReviews";
import { useQueryCurrentUser } from "source/api/users/useQueryUsers";
import { useQueryCellReviews } from "source/api/matrix/useQueryCellReviews";
import { pluralize } from "source/utils/common/strings";
import { ReportsGridContext } from "source/components/matrix/contexts/ReportsGridContext";
import { getSSRMGetRowsSortFilterParams } from "source/utils/matrix/grid/gridUtilLayer";
import { useMatrixSocketApi } from "source/hooks/matrix/useMatrixSocketApi";
import logger from "source/utils/logger";
import { scrollToAndHighlightCell } from "source/components/matrix/tables/helpers/helpers";

export const AssignedCellReviews = () => {
  const dispatch = useAppDispatch();
  const { gridApi } = useContext(ReportsGridContext);

  const closeNotification = () =>
    dispatch(removeSnackbar("assignedCellReviews"));
  const enableCellReviewers = useFeatureFlag("enableCellReviewers");

  const { data: userData } = useQueryCurrentUser();
  const matrixId = useActiveMatrixId();
  const { data: cellReviews } = useQueryCellReviews(
    matrixId,
    enableCellReviewers
  );

  const { assignedReviews } = calculateCellReviewStats(
    cellReviews,
    userData?.user?.id
  );

  const [hasClickedSeeCells, setHasClickedSeeCells] = useState(false);
  const [assignedCellReviewIndex, setAssignedCellReviewIndex] = useState(0);
  const { getRowIndexByCellId } = useMatrixSocketApi();

  const scrollAndFlashCell = async (cellId: string) => {
    if (!gridApi) return;
    const baseParams = getSSRMGetRowsSortFilterParams(gridApi);

    const serverRowIndex = await getRowIndexByCellId({
      ...baseParams,
      cell_id: cellId,
    });
    if (serverRowIndex === undefined) {
      logger.error("Row index not found for cell ID", {
        cellId,
        matrixId,
      });
      return;
    }
    const rowIndex = serverRowIndex;
    // Scroll to and highlight the cell in AGGrid
    scrollToAndHighlightCell(gridApi, cellId, rowIndex);
  };

  useEffect(() => {
    // if the number of assignedReviews changed, then re-index.
    if (assignedReviews.length === 0) {
      closeNotification();
    }
    let newValue: number | null = null;
    if (assignedCellReviewIndex >= assignedReviews.length) {
      newValue = assignedReviews.length - 1;
    } else if (assignedCellReviewIndex < 0) {
      newValue = 0;
    }
    if (newValue !== null) {
      setAssignedCellReviewIndex(newValue);
    }
  }, [assignedReviews]);

  const onClickChevron = async (newIndex: number) => {
    const newCellId = assignedReviews[newIndex]?.cell_hash;
    if (!newCellId) {
      return;
    }
    setAssignedCellReviewIndex(newIndex);
    await scrollAndFlashCell(newCellId);
  };

  const onClickSeeCells = async () => {
    // navigate to left-most cell review
    await onClickChevron(0);
    setHasClickedSeeCells(true);
  };
  const isPrevButtonDisabled = assignedCellReviewIndex === 0;
  const isNextButtonDisabled =
    assignedCellReviewIndex === assignedReviews.length - 1;

  return (
    <Notification
      autoClose={false} // open indefinitely as this is the only way to see cells assigned to me.
      closeNotification={closeNotification}
    >
      <div className="flex items-center justify-between">
        <div className="flex flex-row items-center">
          <div className="flex flex-shrink-0">
            <IconButton variant="text" onClick={closeNotification}>
              <MaterialSymbolsIcon size="xl" icon="close" />
            </IconButton>
          </div>
          <p className="ml-6 text-sm font-medium text-neutral-800">
            You have been assigned {assignedReviews.length}{" "}
            {pluralize(assignedReviews.length, "cell")} to review.
          </p>
        </div>
        {hasClickedSeeCells ? (
          <div className="flex flex-row items-center gap-1">
            <IconButton
              disabled={isPrevButtonDisabled}
              onClick={() => onClickChevron(assignedCellReviewIndex - 1)}
              variant="text"
            >
              <MaterialSymbolsIcon
                size="xl"
                icon="chevron_left"
                className={classNames(
                  !isPrevButtonDisabled && "text-hebbiaBlue"
                )}
              />
            </IconButton>
            <IconButton
              disabled={isNextButtonDisabled}
              onClick={() => onClickChevron(assignedCellReviewIndex + 1)}
              variant="text"
            >
              <MaterialSymbolsIcon
                size="xl"
                icon="chevron_right"
                className={classNames(
                  !isNextButtonDisabled && "text-hebbiaBlue"
                )}
              />
            </IconButton>
          </div>
        ) : (
          <Button
            variant="text"
            className="ml-3 px-0 pt-2 text-xs font-medium text-hebbiaBlue hover:bg-transparent"
            onClick={onClickSeeCells}
          >
            See Cells
          </Button>
        )}
      </div>
    </Notification>
  );
};
