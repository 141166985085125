import { useSelector } from "react-redux";
import { useFeatureFlag } from "../common/useFeatureFlag";
import { getUser } from "source/redux/user";
import { useQueryMatrixLease } from "source/api/matrix/useQueryMatrixLease";
import { getSocketLeaseId } from "source/redux/matrix";
import { useAppDispatch } from "source/redux";
import { upsertSnackbar } from "source/redux/ui";
import logger from "source/utils/logger";
import { useActiveMatrixId } from "./useActiveMatrixId";

export const useMatrixLease = () => {
  const dispatch = useAppDispatch();
  const enablePageBlocking = useFeatureFlag("enablePageBlocking");

  const user = useSelector(getUser);
  const leaseId = useSelector(getSocketLeaseId);
  const matrixId = useActiveMatrixId();

  const isPageBlockingEnabled =
    !!matrixId && enablePageBlocking && user?.platform_role !== "admin";

  const { data: matrixLease, error: matrixLeaseError } = useQueryMatrixLease(
    matrixId ?? "",
    isPageBlockingEnabled
  );

  // If the user doesn't have access to the matrix, block the page
  if (matrixLeaseError && matrixLeaseError.response?.status === 403) {
    logger.error(
      "User does not have access to the matrix but requested the lease",
      {
        matrixId,
        user,
        matrixLeaseError,
      }
    );

    dispatch(upsertSnackbar("matrixAccessError"));

    return {
      blockPage: true, // block page if the user doesn't have access to the matrix
      leaseId: "",
      isLeaseMine: false,
      leaseOwnerId: "",
      leaseOwnerName: "",
      numEditors: 0,
      profilePicture: null,
    };
  }

  const isLeaseMine = user?.id === matrixLease?.user_id;
  const numEditors = matrixLease?.number_of_editors ?? 0;

  // @shirley: Move this into a createSelector
  const blockPage =
    (numEditors > 1 && !matrixLease?.lease_id) ||
    (matrixLease != undefined &&
      leaseId != undefined &&
      matrixLease.lease_id != undefined &&
      matrixLease.lease_id !== leaseId);

  if (isPageBlockingEnabled) {
    return {
      blockPage,
      leaseId: matrixLease?.lease_id,
      leaseOwnerId: matrixLease?.user_id,
      leaseOwnerName: matrixLease?.user_name,
      profilePicture: matrixLease?.profile_picture,
      isLeaseMine,
      numEditors,
    };
  } else {
    return {
      blockPage: false,
      leaseId: "",
      isLeaseMine: false,
      leaseOwnerId: "",
      leaseOwnerName: "",
      numEditors: 0,
      profilePicture: null,
    };
  }
};
