import { useEffect } from "react";
import { modalConfig } from "./modalConfig";

export const usePreloadModals = () => {
  useEffect(() => {
    Object.values(modalConfig).forEach((modal) => {
      modal?.import();
    });
  }, []);
};
