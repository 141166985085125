import { currentModal, CurrentModal } from "./currentModal";
import React, { Suspense, useEffect, useState } from "react";
import { usePreloadModals } from "./usePreloadModals";

export const ModalRenderer = () => {
  const [modal, updateCurrentModal] = useState<CurrentModal | null>(null);

  // Pre-load the modal JS chunks so they are ready to render when needed
  usePreloadModals();

  useEffect(() => {
    const unsubscribe = currentModal.subscribe(updateCurrentModal);
    return unsubscribe;
  }, []);

  if (modal) {
    const Modal = currentModal.get(modal.type);

    return (
      <Suspense>
        <Modal {...modal?.props} />
      </Suspense>
    );
  }

  return null;
};
