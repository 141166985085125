import { SSRMRow } from "source/components/matrix/types/cells.types";
import {
  BulkRunStatusResponse,
  MatrixUser,
  Report,
  ToolSpecType,
} from "source/components/matrix/types/reports.types";
import { MatrixChatDocumentsScope } from "source/types/matrix/matrixChat.types";
import { ToolOutputType } from "source/components/matrix/types/tools.types";
import { MatrixRoleType } from "source/Types";

export type RemoveMatrixUserParams = {
  matrixId: string;
  userId: string;
};

export type AddMatrixUserParams = {
  matrixId: string;
  email: string;
  role: MatrixRoleType;
};

export type EditMatrixUserParams = {
  matrixId: string;
  userId: string;
  role: MatrixRoleType;
};

export type MatrixUsersResponse = {
  [key: string]: MatrixUser;
};

export enum MatrixPermissionLevel {
  PRIVATE = "private",
  PUBLIC = "public",
  ORG = "org",
}

export type MatrixShareSettings = {
  id: string;
  matrixId: string;
  shareSettings: MatrixPermissionLevel;
};

export type MatrixRoleResponse = {
  sheet_id: string;
  role: MatrixRoleType;
};

export type MatrixMetadataResponse = {
  sheet_id: string;
  sheet_org_id: string;
  role: MatrixRoleType;
  is_exclusively_org_viewer?: boolean; // true if a user ONLY has org-level access
  build_status: BulkRunStatusResponse;
  name: string;
  sheet_session_id: string | null;
  bookmarked?: boolean;
  expired_at?: string;
};

export type UpdateShareSettingsParams = {
  matrixId: string;
  permissionLevel: MatrixPermissionLevel;
  createIfMissing: boolean;
};

export type UpdateMatrixBookmakredParams = {
  reportId: string;
  bookmarked: boolean;
};

export enum RowCellState {
  DOCS,
  ANSWERED,
  EMPTY,
  DOCUMENT_FAILED,
}

export type RowStateMapType = {
  [key: string]: RowCellState;
};

export type MatrixLeaseResponse = {
  lease_id: string;
  user_id: string;
  user_email: string;
  user_name: string;
  number_of_editors: number;
  profile_picture: string;
};

export type GeneratePromptRequest = {
  userInput: string;
  sheetId: string;
  outputType?: string;
  outputFormat?: string;
};

export type GeneratePromptResponse = {
  prompt: string;
  output_type: ToolOutputType;
  tool_spec: ToolSpecType;
  scale?: string; // this field will be set on the view-config
};

export type SuggestColumnsResponse = {
  columns: ColumnType[];
};

export type ColumnType = {
  column_name: string;
  field_type: string;
  field_reasoning: string;
  description: string;
};

export type SuggestChartsResponse = {
  tako_cards: {
    url: string;
    iframe: string;
    chart_description: string;
    title: string;
    source_url: string;
    specificity: string;
    pub_id: string;
  }[];
};

export type TemplateMetadataParams = {
  template_id: string;
  template_metadata: {
    pinned: boolean;
  };
};

export enum PinnedPromptType {
  MATRIX = "matrix",
  USER = "user",
  TEMPLATE = "template",
}

export type ListPinnedPromptsParams = {
  types: PinnedPromptType[];
  sheet_id?: string;
};

export type CreatePinnedPromptParams = {
  name: string;
  prompt: string;
  document_scope: MatrixChatDocumentsScope;
  is_read_only: boolean;
  sheet_id?: string;
};

export type UpdatePinnedPromptParams = {
  matrix_chat_prompt_id: string;
  name?: string;
  prompt?: string;
  document_scope?: MatrixChatDocumentsScope;
  is_read_only?: boolean;
};

export type ListPinnedPromptsResponse = {
  user: PinnedPromptResponse[];
  matrix: PinnedPromptResponse[];
  template: PinnedPromptResponse[];
};
export type PinnedPromptResponse = {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
  prompt: string;
  name: string;
  document_scope: MatrixChatDocumentsScope;
  is_read_only: boolean;
  document_list_id?: string;
  template_id?: string;
  sheet_id?: string;
  user_id?: string;
};
export enum CellReviewState {
  ASSIGNED = "assigned",
  VERIFIED = "verified",
  BLANK = "blank",
}

export type CellReview = {
  id: string;
  cell_id: string;
  cell_hash?: string | null;
  versioned_column_id?: string | null;

  sheet_id: string;
  review_state: CellReviewState;
  is_corrected: boolean;
  verified_at?: string | null;

  verifier_user_id?: string | null;
  assignee_user_id?: string | null;
  assigner_user_id: string;
};

export type CellReviewWithHydratedUsers = CellReview & {
  assigneeUser?: MatrixUser | null;
  assignerUser?: MatrixUser | null;
  verifierUser?: MatrixUser | null;
};

export type CreateCellReviewParams = {
  cell_hash: string;
  versioned_column_id?: string | null;
  static_column_id?: string | null;
  sheet_id: string;

  review_state?: CellReviewState | null;
  verifier_user_id?: string | null;
  verified_at?: string | null;
  assignee_user_id?: string | null;
  is_corrected?: boolean | null;
};

export type UpdateCellReviewParams = {
  id: string;
  review_state?: CellReviewState | null;
  is_corrected?: boolean | null;
  verifier_user_id?: string | null;
  verified_at?: string | null;
  assignee_user_id?: string | null;
};

export type HashCell = {
  cell_hash: string;
  versioned_column_id: string;
};

export type BulkActionCellReviewParams = {
  sheet_id: string;
  hash_cells: HashCell[];
  review_state: CellReviewState;
  verifier_user_id?: string | null;
  verified_at?: string | null;
  assignee_user_id?: string | null;
};
