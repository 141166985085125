import { getFileType } from "../matrix/fastBuild";

export const isAllItemsDirectories = (items: DataTransferItemList) => {
  return Array.from(items).every((item) => {
    return item.webkitGetAsEntry()?.isDirectory;
  });
};

export const getAllFileEntries = async (
  dataTransferItemList: DataTransferItemList
) => {
  const fileEntries: FileSystemEntry[] = [];
  // use BFS to traverse entire directory/file structure
  const queue: (FileSystemEntry | FileSystemDirectoryEntry)[] = [];
  // dataTransferItemList is not iterable, no forEach
  for (let i = 0; i < dataTransferItemList.length; i++) {
    const webkitEntry = dataTransferItemList?.[i]?.webkitGetAsEntry();
    if (webkitEntry) queue.push(webkitEntry);
  }
  while (queue.length > 0) {
    const entry = queue.shift();
    if (!entry) break;
    if (entry.isFile) {
      fileEntries.push(entry);
    } else if (entry.isDirectory) {
      const reader = (entry as FileSystemDirectoryEntry).createReader();
      queue.push(...(await readAllDirectoryEntries(reader)));
    }
  }
  const getFile = async (fileEntry) => {
    const file: File = (await new Promise((resolve, reject) =>
      fileEntry.file(resolve, reject)
    )) as File;
    // Ensure `.msg` files have their type set correctly
    const fileType = getFileType(file);
    // this is an ugly hack because File.name is readonly
    const newFile = new File([file], fileEntry.fullPath, { type: fileType });
    return newFile;
  };
  return await Promise.all(fileEntries.map((entry) => getFile(entry)));
};

export const readAllDirectoryEntries = async (
  directoryReader: DirectoryReader
) => {
  const entries: FileSystemEntry[] = [];
  let readEntries: FileSystemEntry[] =
    await readEntriesPromise(directoryReader);
  while (readEntries.length > 0) {
    entries.push(...readEntries);
    readEntries = await readEntriesPromise(directoryReader);
  }
  return entries;
};

export const readEntriesPromise = async (
  directoryReader: DirectoryReader
): Promise<FileSystemEntry[]> => {
  try {
    return await new Promise((resolve, reject) => {
      directoryReader.readEntries(resolve, reject);
    });
  } catch (err) {
    return [];
  }
};

/* eslint-disable no-useless-escape */
export const shouldCreateFolder = (filePath?: string): boolean => {
  if (!filePath) return false;
  // Check if the path contains at least one slash, followed by a valid filename with an extension
  return /^.+\/.+\..+$/.test(filePath);
};

export const removeLeadingSlashFromFilename = (filePath: string | undefined) =>
  filePath?.replace(/^\/+/, "");
