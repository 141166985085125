import { getActiveReport, getDisplayReportIsGiga } from "source/redux/matrix";
import { useSelector } from "react-redux";
import { useFeatureFlag } from "../common/useFeatureFlag";
import { useMemo } from "react";
import { useQueryMatrixMetadata } from "source/api/matrix/useQueryReports";
import { useMatrixLease } from "./useMatrixLease";

export const useMatrixAccess = (reportId?: string) => {
  const report = useSelector(getActiveReport);
  const { data: metadata, isLoading: metadataLoading } = useQueryMatrixMetadata(
    reportId || report?.id || ""
  );
  const isGiga = useSelector(getDisplayReportIsGiga);
  const gigaEnabled = useFeatureFlag("gigaEnabled"); // admin creation
  // Special matrixes to force viewer only access
  const viewerOnlyMatrixes = useFeatureFlag("viewerOnlyMatrixes");

  /** Determine if another has the matrix open. */
  const { blockPage } = useMatrixLease();

  // Role defaults to viewer while metadata is loading
  const accessType = metadata?.role ?? "viewer";

  const isGigaAdmin = useMemo(() => {
    return !!(isGiga && gigaEnabled);
  }, [isGiga, gigaEnabled]);
  const isGigaViewer = useMemo(() => {
    return isGiga && !gigaEnabled;
  }, [isGiga, gigaEnabled]);
  const isViewer = useMemo(() => {
    return (
      accessType === "viewer" ||
      (viewerOnlyMatrixes ?? []).includes(report?.id ?? "") ||
      blockPage
    );
  }, [accessType, report?.id, viewerOnlyMatrixes, blockPage]);
  const isOwner = useMemo(() => {
    return accessType === "owner";
  }, [accessType]);

  return {
    isGigaAdmin,
    isGigaViewer,
    isViewer,
    isOrgViewer: metadata?.is_exclusively_org_viewer, // subset of isViewer, if user ONLY has org-level access
    isOwner,
    isLoading: metadataLoading,
    isExpired: !!metadata?.expired_at,
  };
};
